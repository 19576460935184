<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12">
          <v-combobox
            solo
            class="right-input"
            v-model="order"
            :items="items"
            :auto-select-first="items.length == 1"
            :search-input.sync="controls.search"
            hide-no-data
            hide-selected
            item-text="Text"
            item-value="Value"
            @change="change"
          >
            <template v-slot:append-outer class="add">
              <v-btn
                class="add-product"
                fab
                dark
                color="primary"
                @click="add"
                small
              >
                <v-icon dark>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-combobox>
          <v-data-table
            :items="data"
            class="elevation-1"
            hide-default-actions
            hide-default-headers
            hide-default-footer
            item-key="_temp"
            :headers="headers"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
            </template>
            <template
              v-slot:[`item.actions`]="{ index }"
              class="justify-center layout px-0"
            >
              <v-icon
                small
                class="mr-2"
                @click="deleteItem(index)"
                title="Delete/Eliminar"
                >mdi-delete-sweep</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="primary" text @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    onAdd: Function,
    onRemove: Function,
    onCloseDialog: Function,
    controlSearch: Function,
  },
  data() {
    return {
      order: null,
      data: [],
      headers: [
        {
          text: "Order",
          align: "start",
          value: "order",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      controls: {
        entries: [],
        search: null,
      },
    };
  },
  watch: {
    "controls.search": function (newVal, _oldVal) {
      this.controlSearch(newVal).then((res) => {
        this.controls.entries = res;
      });
    },
  },
  computed: {
    items() {
      return this.controls.entries.map((entry) => {
        return entry;
      });
    },
  },
  methods: {
    close() {
      console.log(this.data);
      this.onCloseDialog(this.data);
    },
    async change(model) {
      this.order = model;
    },
    deleteItem(index) {
      if (confirm("Are you sure you want to delete this item?")) {
        const item = this.data.splice(index, 1);
        //this.$emit("input", this.value);
        if (this.onRemove) {
          this.onRemove(item[0]);
        }
      }
    },
    add() {
      console.log(this.controls.search);
      if (this.controls.search == "" || this.controls.search == null) return;
      const newVal = {
        order: this.controls.search,
        id: this.guid(),
      };
      this.data.push(newVal);
      this.order = "";
      this.controls.search = "";
      if (this.onAdd) {
        this.onAdd(newVal);
      }
    },
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
  },
};
</script>

<style>
.add-product {
  margin: 0;
  padding: 0;
  top: -12px;
}
</style>
